<template>
  <div v-if="isLoggedIn">
    <v-app-bar
      v-if="!isUnderMaintenance"
      clipped-left
      app
      dense
      fixed
      flat
      class="primary"
      dark
    >
      <v-btn
        v-if="!$store.state.settings.isMenuOpen"
        icon
        class="mr-3 ml-n1"
        @click="isMenuOpen = true"
      >
        <v-icon>$menu</v-icon>
      </v-btn>
      <span
        v-if="environment !== 'production'"
        class="text-uppercase font-weight-bold"
        style="font-size: 1.825rem; letter-spacing: 0.175em;"
      >
        {{ environment }}
      </span>
      <VSpacer />
      <TheInternalNoteHandler />
      <v-btn
        class="mr-2"
        icon
        @click="$refs.theTaskManager.toggleDrawer()"
      >
        <v-badge
          :value="hasUnreadTasks"
          color="error"
          dot
        >
          <v-icon>$notifications</v-icon>
        </v-badge>
      </v-btn>
      <AppBarMenu />
    </v-app-bar>
    <VAppBar
      v-else
      clipped-left
      app
      dense
      flat
      color="primary"
    />
    <TheMainMenu />
    <TheTaskManager
      v-if="!isUnderMaintenance"
      ref="theTaskManager"
      @taskCountChanged="onTaskCountChange"
    />
    <v-main>
      <v-row
        v-if="!isUnderMaintenance"
        align="center"
        class="logo-wrapper ma-0 mx-6 mt-2"
      >
        <Breadcrumbs class="breadcrumbs" />
      </v-row>
      <router-view />
    </v-main>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import AppBarMenu from '@/components/menu/AppBarMenu.vue';
import TheMainMenu from '@/components/menu/TheMainMenu.vue';
import TheTaskManager from '@/components/menu/TheTaskManager.vue';
import { mapGetters } from 'vuex';
import TheInternalNoteHandler from '@/modules/internalNote/components/TheInternalNoteHandler.vue';

export default {
  name: 'Default',
  components: {
    TheInternalNoteHandler,
    AppBarMenu,
    Breadcrumbs,
    TheTaskManager,
    TheMainMenu,
  },
  data() {
    return {
      taskCount: {},
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'authorisation/isLoggedIn',
      isUnderMaintenance: 'status/isUnderMaintenance',
    }),
    environment() {
      return process.env.NODE_ENV;
    },
    hasUnreadTasks() {
      return this.taskCount.hasUnreadTasks || false;
    },
    isMenuOpen: {
      get() {
        return this.$store.state.settings.isMenuOpen;
      },
      set(value) {
        this.$store.commit('settings/setMenuState', value);
      },
    },
  },
  created() {
    if (!this.isLoggedIn) {
      this.$router.push({
        name: 'login',
      });
    }
  },
  methods: {
    onTaskCountChange(data) {
      this.taskCount = data;
    },
  },
};
</script>

<style lang="scss">
.main-app-bar {
  .v-toolbar__content {
    padding: 0 !important;
  }
}
</style>

<template>
  <v-dialog
    v-model="showDialog"
    max-width="600"
  >
    <v-sheet class="pa-6">
      <v-row>
        <v-col>
          <h4 class="primary--text">
            {{ $t(`task.taskCompleteDialog.title`) }}
          </h4>
        </v-col>
        <v-btn
          color="grey lighten-2"
          fab
          x-small
          dark
          :elevation="0"
          @click="closeDialog"
        >
          <v-icon color="black">
            $close
          </v-icon>
        </v-btn>
      </v-row>
      <v-row>
        <v-col
          v-if="commentRequired"
          class="text-body-1"
        >
          {{ $t(`task.taskCompleteDialog.bodyCommentRequired`) }}
        </v-col>
        <v-col
          v-else
          class="text-body-1"
        >
          {{ $t(`task.taskCompleteDialog.bodyCommentNotRequired`) }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <KTextarea
            v-model="comment"
            :required="commentRequired"
            :field="$t('task.taskCompleteDialog.comment')"
            :autofocus="commentRequired"
            outlined
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <VSpacer />
        <k-btn
          text
          class="mr-2"
          @click="closeDialog"
        >
          {{ $t('actions.cancel') }}
        </k-btn>
        <k-btn
          :disabled="commentRequired && !comment"
          @click="handleConfirm"
        >
          {{ $t('actions.continue') }}
        </k-btn>
      </v-row>
    </v-sheet>
  </v-dialog>
</template>

<script>
import eventBus from '@/application/eventBus';
import KTextarea from '@/components/crud/fields/KTextarea.vue';
import KBtn from '@/components/KButton.vue';

export default {
  name: 'TaskCompleteDialog',
  components: {
    KBtn,
    KTextarea,
  },
  data: () => ({
    showDialog: false,
    confirmCallback: null,
    commentRequired: null,
    comment: '',
  }),
  created() {
    eventBus.$on('taskComplete', ({ task, callback }) => {
      this.showDialog = true;
      this.comment = '';
      this.commentRequired = task.commentRequired,
      this.confirmCallback = callback;
    });
  },
  methods: {
    handleConfirm() {
      this.closeDialog();
      this.confirmCallback({
        comment: this.comment,
      });
    },
    closeDialog() {
      this.showDialog = false;
    },
  },
};
</script>

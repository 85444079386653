<script>
import { getStatus, pickUp, complete } from '@/modules/task/api/task.js';
import eventBus from '@/application/eventBus.ts';
import { mapGetters } from 'vuex';

export default {
  name: 'Task',
  computed: {
    ...mapGetters('authorisation', ['userId']),
  },
  methods: {
    async completeTask(task, completeCallback) {
      try {
        await eventBus.$emit('taskComplete', {
          task,
          callback: async (payload) => {
            await complete(task.id, payload);
            
            eventBus.$emit('snackbar', {
              color: 'success',
              text: this.$t('task.snackbar.completed'),
            });

            if (typeof completeCallback === 'function') {
              completeCallback();
            }

          },
        });
      } catch (error) {
        eventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.generic'),
        });
        throw error;
      }
    },
    async pickUpTask(task, completeCallback) {
      const {
        data: {
          data: {
            completed,
            pickedUp,
            pickedUpBy,
          },
        },
      } = await getStatus(task.id);

      if (!completed && pickedUp && pickedUpBy.id !== this.userId) {
        eventBus.$emit('confirm', {
          title: this.$t('task.alreadyPickedUp.title'),
          body: this.$t('task.alreadyPickedUp.content', { name: pickedUpBy.fullName }),
          confirmCallback: async () => {
            await pickUp(task.id);

            eventBus.$emit('snackbar', {
              color: 'success',
              text: this.$t('task.snackbar.takenAway'),
            });

            if (typeof completeCallback === 'function') {
              completeCallback();
            }
          },
        });
        return;
      }

      if (completed) {
        eventBus.$emit('confirm', {
          title: this.$t('task.alreadyCompleted.title'),
          body: this.$t('task.alreadyCompleted.content', { name: pickedUpBy.fullName }),
          showCancel: false,
        });
        return;
      }

      await pickUp(task.id);

      eventBus.$emit('snackbar', {
        color: 'success',
        text: this.$t('task.snackbar.pickedUp'),
      });

      if (typeof completeCallback === 'function') {
        completeCallback();
      }
    },
  },
};
</script>

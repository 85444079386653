<template>
  <div>
    <v-btn
      class="mr-2"
      icon
      :disabled="!isActive"
      @click="openDialog"
    >
      <v-icon>$internalNote</v-icon>
    </v-btn>

    <v-dialog
      v-model="dialog"
      content-class="internal-note-dialog"
      transition="dialog-top-transition"
      max-width="600"
    >
      <v-card
        tile
        class="pa-3"
      >
        <k-field-group language-prefix="internalNote.fields">
          <v-form
            ref="form"
            @submit.prevent="addNote"
          >
            <v-card-title>
              <h3 class="text-h3 primary--text text--darken-3">
                {{ $tc('internalNote.title', 1) }}
              </h3>
              <VSpacer />
              <v-btn
                color="primary"
                outlined
                tile
                @click="dialog = false"
              >
                {{ $t('actions.cancel') }}
              </v-btn>
              <v-btn
                color="primary"
                class="ml-3"
                tile
                depressed
                type="submit"
              >
                {{ $t('actions.save') }}
              </v-btn>
            </v-card-title>
            <v-card-text>
              <KTextarea
                v-model="form.note"
                field="note"
              />
            </v-card-text>
          </v-form>
        </k-field-group>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextarea from '@/components/crud/fields/KTextarea.vue';
import { create } from '@/modules/internalNote/api/internalNote.js';

export default {
  name: 'TheInternalNoteHandler',
  components: {
    KTextarea,
    KFieldGroup,
  },
  data: () => ({
    dialog: false,
    scope: '',
    scopeId: undefined,
    form: {
      note: '',
    },
  }),
  computed: {
    isActive() {
      return (!!this.scope || !!this.scopeId);
    },
  },
  created() {
    eventBus.$on('internalNote', (note) => {
      this.scope = note?.scope;
      this.scopeId = note?.scopeId;
    });
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    async addNote() {
      try {
        await create(this.scope, this.scopeId, this.form.note);
        this.$refs.form.reset();
        eventBus.$emit('snackbar', {
          color: 'success',
          text: this.$t('internalNote.snackbar.created'),
        });
        eventBus.$emit('internalNote.created');
        this.dialog = false;
      } catch (e) {
        this.$refs.form.validate();
      }
    },
  },
};
</script>

<style lang="scss">
.internal-note-dialog {
  align-self: flex-start;
  margin-top: 0;
}
</style>

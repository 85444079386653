<template>
  <div id="TheTaskManager">
    <TaskDrawer
      ref="TaskDrawer"
      v-model="drawer"
      :index-request="summary"
      :overwrite-data="false"
      disable-resize-watcher
    />
  </div>
</template>

<script>
import { getMyTaskCount, summary } from '@/modules/task/api/task';
import TaskDrawer from '@/modules/task/components/Drawer.vue';

export default {
  name: 'TheTaskManager',
  components: { TaskDrawer },
  data() {
    return {
      drawer: false,
      notifications: [{ id: 1 }],
      loading: false,
      pagination: {
        page: 1,
        lastPage: null,
      },
      taskCountPoller: null,
    };
  },
  watch: {
    drawer(value) {
      this.$refs.TaskDrawer.resetData();
      if (value) this.$refs.TaskDrawer.getData();
    },
  },
  created() {
    this.resetTaskCountPoller();
    this.fetchMyTaskCount();
    this.taskCountPoller = setInterval(() => this.fetchMyTaskCount(), 60000);
  },
  destroyed() {
    this.resetTaskCountPoller();
  },
  methods: {
    summary,
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    async fetchMyTaskCount() {
      const { data: { data } } = await getMyTaskCount();
      this.$emit('taskCountChanged', data);
    },
    resetTaskCountPoller() {
      clearInterval(this.taskCountPoller);
    },
  },
};
</script>

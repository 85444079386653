<template>
  <v-icon :color="color">
    {{ icon }}
  </v-icon>
</template>

<script>
import priority from '@/application/enums/priority.js';

export default {
  name: 'TaskPriorityIcon',
  props: {
    priority: {
      type: Number,
    },
  },
  computed: {
    color() {
      if (this.priority === priority.CRITICAL) return 'error';
      if (this.priority === priority.HIGH) return 'warning';
      if (this.priority === priority.NORMAL) return 'info';
      if (this.priority === priority.LOW) return 'success';
      if (this.priority === priority.MINOR) return 'grey';
      return 'grey';
    },
    icon() {
      if (this.priority === priority.CRITICAL) return '$prioCritical';
      if (this.priority === priority.HIGH) return '$prioHigh';
      if (this.priority === priority.NORMAL) return '$prioNormal';
      if (this.priority === priority.LOW) return '$prioLow';
      if (this.priority === priority.MINOR) return '$prioMinor';
      return '$unknown';
    },
  },
};
</script>

<template>
  <v-navigation-drawer
    v-model="isMenuOpen"
    app
    clipped
    hide-overlay
    width="260px"
  >
    <v-row class="fill-height my-0">
      <v-col class="pl-0 pt-0 sub-menu secondary">
        <Menu
          class="ml-10 mt-10"
          :menu-items="authorisedMenuItems"
        />
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import Menu from '@/components/menu/KMenu.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'TheMainMenu',
  components: {
    Menu,
  },
  computed: {
    ...mapGetters({
      can: 'authorisation/can',
    }),
    isMenuOpen: {
      get() {
        return this.$store.state.settings.isMenuOpen;
      },
      set(value) {
        this.$store.commit('settings/setMenuState', value);
      },
    },
    menuItems() {
      return [
        {
          title: this.$t('global.home'),
          route: { name: 'dashboard' },
          icon: '$home',
        },
        {
          title: this.$tc('vacancy.title', 2),
          route: { name: 'vacancy.index' },
          permission: 'vacancy.show',
          icon: '$vacancy',
        },
        {
          title: this.$tc('shift.title', 2),
          route: { name: 'shift.index' },
          permission: 'shift.show',
          icon: '$shift',
        },
        {
          title: this.$tc('timeRegistration.title', 2),
          route: { name: 'timeRegistration.index' },
          permission: 'timeRegistration.show',
          icon: '$time',
        },
        {
          title: this.$tc('expenseClaim.title', 2),
          route: { name: 'expenseClaim.index' },
          permission: 'expenseClaim.show',
          icon: '$expense',
        },
        {
          title: this.$tc('invoice.title', 2),
          route: { name: 'invoice.concept.index' },
          permission: 'invoice.show',
          icon: '$invoice',
        },
        {
          title: this.$tc('candidate.title', 2),
          route: { name: 'candidate.index' },
          permission: 'candidate.show',
          icon: '$candidate',
        },
        {
          title: this.$tc('employer.title', 2),
          route: { name: 'employer.index' },
          permission: 'employer.show',
          icon: '$employer',
        },
        {
          title: this.$tc('report.title', 2),
          route: { name: 'report.show' },
          // @TODO: fix when there is a specified permission for this
          permission: 'employer.show',
          icon: '$report',
          exact: false,
        },
        {
          title: this.$tc('placement.title', 2),
          route: { name: 'placement.index' },
          permission: 'placement.show',
          icon: '$placement',
        },
      ];
    },
    authorisedMenuItems() {
      return this.menuItems.filter(item => item.permission ? this.can(item.permission) : true);
    },
  },
  methods: {
    handleLogoClick() {
      if (this.$route.name === 'dashboard') return;
      this.$router.push({ name: 'dashboard' });
    },
  },
};
</script>

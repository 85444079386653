import { getPaginated } from '@/application/api/implementations/app/wrapper.js';
import { get, post } from '@/application/api/implementations/app';

//export const index = (...args) => getPaginated('task', ...args);
export const index = (page, perPage, search, sortBy, descending, params) => {
  params = {
    ...!!params.assignedToMe && { assignedToMe: params.assignedToMe ? 1 : 0 },
    ...!!params.pickedUpByMe && { pickedUpByMe: params.pickedUpByMe ? 1 : 0 },
    ...!!params.notAssigned && { notAssigned: params.notAssigned ? 1 : 0 },
    ...!!params.priorities && { priorities: Array.isArray(params.priorities) ? params.priorities : [params.priorities] },
    ...!!params.refersTo && { refersTo: Array.isArray(params.refersTo) ? params.refersTo : [params.refersTo] },
    ...params.isCompleted !== null && { isCompleted: params.isCompleted ? 1 : 0 },
  };
  return getPaginated('task', page, perPage, search, sortBy, descending, params);
};

export const summary = (...args) => getPaginated('task/summary', ...args);

export const pickUp = (taskId) => post(`task/${taskId}/pick-up`);

export const complete = (taskId, payload) => post(`task/${taskId}/complete`, payload);

export const getStatus = (taskId) => get(`task/${taskId}/status`);

export const getMyTaskCount = () => get('task/my-task-count');

/**
 *
 * @param {Object} params - The query-parameters you wish to pass to the request.
 * @param {string} params.type - The type of statistics which should be fetched. Choose one: my, my-role, all.
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getStatistic = (params) => get('report/task/statistic', { params });

export default function (refersTo, entities) {
  const routeName = referToRouteName(refersTo);

  if (!routeName) return;

  return {
    name: routeName,
    params: entities,
  };
}

const referToRouteName = (refersTo) => {
  if (refersTo === 'candidate') return 'candidate.show';
  if (refersTo === 'contract') return 'candidate.contract';
  if (refersTo === 'placement') return 'candidate.placement';
  if (refersTo === 'shift') return 'shift.show';
  if (refersTo === 'time_registration') return 'timeRegistration.show';
  if (refersTo === 'declaration') return 'expenseClaim.show';
  if (refersTo === 'invoice') return 'invoice.pdf';
  if (refersTo === 'collective_agreement') return;

  throw `refersTo:: refersTo is not recognised. You need to integrate the '${refersTo}' type in taskToRoute`;
};

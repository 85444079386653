<template>
  <v-menu
    bottom
    left
    offset-y
    transition="slide-y-transition"
  >
    <template #activator="{ on }">
      <v-btn
        icon
        v-on="on"
      >
        <v-icon>$profile</v-icon>
      </v-btn>
    </template>
    <v-list class="pt-0">
      <v-list-item>
        <v-list-item-title class="d-flex align-center py-2">
          <v-icon
            small
            color="primary"
            class="mr-3"
          >
            $profileSolid
          </v-icon>
          <div class="d-flex flex-column">
            <span class="text-subtitle-2 primary--text">
              {{ [profile.firstName, profile.insertion, profile.lastName].join(' ') }}
            </span>
            <span class="text-caption mt-n1 secondary--text">{{ profile.email }}</span>
          </div>
        </v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list-item
        v-if="can('organisationSettings.show')"
        :to="{ name: 'settings' }"
      >
        <v-list-item-title>{{ $t('global.organisationSettings') }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="handleLogout">
        <v-list-item-title>{{ $t('authorisation.actions.logOff') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppBarMenu',
  methods: {
    ...mapActions({
      logout: 'authorisation/logout',
    }),
    handleLogout() {
      this.logout();
      this.$router.push({ name: 'login' });
    },
  },
  computed: {
    ...mapGetters({
      profile: 'authorisation/profile',
      can: 'authorisation/can',
    }),
  },
};
</script>

<template>
  <v-list-item
    v-bind="$attrs"
    :input-value="false"
    :to="taskRoute"
    class="px-2 mb-1"
    exact
    v-on="$listeners"
  >
    <v-list-item-icon class="mr-3">
      <TaskPriorityIcon :priority="task.priorityId" />
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title
        class="no-truncate"
        :class="task.read ? 'font-weight-light' : 'font-weight-bold'"
      >
        {{ task.name }}
      </v-list-item-title>
      <v-list-item-subtitle
        v-if="task.subtitle"
        class="no-truncate"
      >
        {{ task.subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="task.pickedUpByMe && task.canBeManuallyCompleted">
      <v-menu
        offset-y
        left
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            icon
            @click.prevent="on.click"
            @mousedown.prevent
          >
            <v-icon>$expand</v-icon>
          </v-btn>
        </template>
        <v-list nav>
          <v-list-item @click="manuallyComplete">
            <v-list-item-title>{{ $t('task.actions.complete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item-action>
    <v-list-item-action v-if="!task.pickedUpByMe">
      <v-menu
        offset-y
        left
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            icon
            @click.prevent="on.click"
            @mousedown.prevent
          >
            <v-icon>$expand</v-icon>
          </v-btn>
        </template>
        <v-list nav>
          <v-list-item @click="handlePickUp">
            <v-list-item-title>{{ $t('task.actions.pickUp') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import TaskPriorityIcon from '@/modules/task/components/TaskPriorityIcon.vue';
import taskToRoute from '@/modules/task/util/taskToRoute.js';
import Task from '@/modules/task/mixins/Task.vue';

export default {
  name: 'TaskListItem',
  components: {
    TaskPriorityIcon,
  },
  mixins: [Task],
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  computed: {
    taskRoute() {
      return taskToRoute(this.task.refersTo, this.task.entities);
    },
  },
  methods: {
    handlePickUp() {
      this.pickUpTask(this.task, () => this.$emit('pickedUpByMe'));
    },
    manuallyComplete() {
      this.completeTask(this.task, () => this.$emit('taskComplete'));
    },
  },
};
</script>

<style scoped>
:deep(.theme--light.v-list-item--active:hover::before), .theme--light.v-list-item--active::before {
  opacity: 0;
}

.no-truncate {
  white-space: unset;
}
</style>

<template>
  <div id="TaskDrawer">
    <k-sidebar
      ref="KSidebar"
      v-bind="$attrs"
      :title="totalTasksText"
      :with-save-button="false"
      app
      right
      width="300"
      v-on="$listeners"
    >
      <template #default>
        <v-alert
          :value="isRejected"
          border="bottom"
          class="body-2 mx-2"
          type="warning"
        >
          {{
            $t('task.rejectedTask')
          }}
        </v-alert>

        <v-list
          dense
          subheader
        >
          <v-row
            justify="center"
            no-gutters
          >
            <h3
              v-if="!loaded || assignedToMe.length"
              class="text-subtitle-2 mb-3"
            >
              {{ $t('task.assignedToMe') }}
            </h3>
            <VSpacer />
            <router-link
              :to="{ name: 'task' }"
              class="text-caption text-decoration-underline"
              @click.native="$emit('input', false)"
            >
              {{ $t('task.goToOverview') }}
            </router-link>
          </v-row>
          <TaskListItem
            v-for="(item, index) in assignedToMe"
            :key="`assignedToMe[${index}]`"
            :task="item"
            @pickedUpByMe="item.pickedUpByMe = true"
            @taskComplete="getData"
          />
          <TaskCompleteDialog />
          <VSkeletonLoader
            v-if="!loaded"
            type="list-item-two-line@3"
            class="mx-n3"
          />

          <h3
            v-if="!loaded || toBeAssigned.length"
            class="text-subtitle-2 mt-5 mb-3"
          >
            {{ $t('task.toBeAssigned') }}
          </h3>
          <TaskListItem
            v-for="(item, index) in toBeAssigned"
            :key="`toBeAssigned[${index}]`"
            :task="item"
            @pickedUpByMe="item.pickedUpByMe = true"
          />
          <VSkeletonLoader
            v-if="!loaded"
            type="list-item-two-line@3"
            class="mx-n3"
          />
        </v-list>

        <template v-if="loaded && isPending">
          <VSkeletonLoader type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line" />
        </template>
      </template>
    </k-sidebar>
  </div>
</template>

<script>
import Paginator from '@/application/mixins/Paginator.old.vue';
import KSidebar from '@/components/layout/KSidebar.vue';
import TaskListItem from '@/modules/task/components/TaskListItem.vue';
import TaskCompleteDialog from '@/modules/task/components/TaskCompleteDialog.vue';

export default {
  name: 'TaskDrawer',
  components: {
    TaskListItem,
    KSidebar,
    TaskCompleteDialog,
  },
  mixins: [Paginator],
  data() {
    return {
      paginationIsCalled: false,
      pagination: {
        perPage: 30,
      },
      fillContentTimeout: null,
    };
  },
  computed: {
    totalTasksText() {
      if (!this.loaded) return this.$tc('task.title', 2);
      return `${this.$tc('task.title', 2)} (${this.paginator.meta.total})`;
    },
    assignedToMe() {
      return this.paginator.data.filter(({ pickedUpByMe }) => pickedUpByMe);
    },
    toBeAssigned() {
      return this.paginator.data.filter(({ pickedUpByMe }) => !pickedUpByMe);
    },
    isPending() {
      return !this.isRejected && this.isLoading;
    },
    isRejected() {
      return !!this.error;
    },
  },
  mounted() {
    const drawerContent = this.$refs.KSidebar.$el.querySelector('.v-navigation-drawer__content');
    drawerContent.addEventListener('scroll', (event) => {
      const isLastPage = this.paginator?.meta?.lastPage <= this.paginator?.meta?.currentPage;
      if (!isLastPage) this.paginatorOnScroll(event);
    });
  },
  methods: {
    paginatorOnScroll(event) {
      /**
       * ScrollTop is the upper most position of the scroll compared to the total height of the scrollable area
       * (which you can see) which is the OffsetHeight. max scrollTop with offsetHeight equals scrollHeight
       */
      const isLastPage = this.paginator?.meta?.lastPage <= this.paginator?.meta?.currentPage;
      const {
        scrollTop,
        offsetHeight,
        scrollHeight,
      } = event.target;
      const scrollTriggerPoint = scrollHeight - (offsetHeight * 0.25);
      const currentScrollHeight = scrollTop + offsetHeight;
      if (currentScrollHeight > scrollTriggerPoint && !this.isPending && !isLastPage) this.getNextPageData();
    },
    getNextPageData() {
      this.overwriteData = false;
      this.pagination.page++;
      return this.getData();
    },
    resetData() {
      this.loaded = false;
      this.paginator = { data: [] };
      this.pagination = { perPage: 30 };
      this.overwriteData = true;
    },
    getData() {
      this.reloadPaginator();
    },
  },
};
</script>
